import { GetStaticProps } from 'next';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { Button } from '@/components/ui/button';
import notFoundSrc from '../../public/images/not-found-icon.webp';
const NotFound = () => {
  const {
    t
  } = useTranslation();
  return <section id="not-found" className="w-full flex items-center flex-col p-20" data-sentry-component="NotFound" data-sentry-source-file="404.tsx">
      <Image src={notFoundSrc} alt={t('IMAGES.NOT_FOUND')} width={1100} height={629} data-sentry-element="Image" data-sentry-source-file="404.tsx" />
      <Link href="/" className="mt-12 w-full max-w-[481px]" data-sentry-element="Link" data-sentry-source-file="404.tsx">
        <Button variant="login" className="w-full" size="lg" suppressHydrationWarning data-sentry-element="Button" data-sentry-source-file="404.tsx">
          {t('NOT_FOUND.BUTTON')}
        </Button>
      </Link>
    </section>;
};
export const getStaticProps: GetStaticProps = async context => {
  const locale = context.locale || 'bg'; // Default to 'bg' if locale is undefined

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common']))
    }
  };
};
export default NotFound;